<template>
  <div class="address-input">
    <div>
      <p><span v-if="required" class="font-bold" style="color: #F56C6C">*</span> 選擇縣市</p>
      <div class="flex" style="gap: 10px">
        <BaseElFormItem prop="address.city">
          <BaseElSelect v-model="syncCity" :disabled="disabledAll" placeholder="請選擇縣市" @change="syncArea=''">
            <BaseElSelectOption
              v-for="city in citiesList"
              :key="city.value"
              :label="city.label"
              :value="city.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem prop="address.area">
          <BaseElSelect v-model="syncArea" :disabled="disabledAll" placeholder="請選擇區域">
            <BaseElSelectOption
              v-for="area in avaliableAreas"
              :key="area.value"
              :label="area.label"
              :value="area.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </div>
      <BaseElFormItem class="detail" label="詳細地址" style="margin-top: 14px">
        <BaseElInput v-model="syncDetail" :disabled="disabledAll" placeholder="請輸入詳細地址" />
      </BaseElFormItem>
    </div>
  </div>
</template>

<script>
import AddressModel from '@/utils/address'
import cities from '@/assets/data/cities'
import { uniqBy } from 'lodash'

export default {
  name: 'AddressInput',
  props: {
    data: {
      type: String,
    },
    city: {
      type: [Number, String],
      required: true,
    },
    area: {
      type: [Number, String],
      required: true,
    },
    detail: {
      type: [Number, String],
      required: true,
    },
    disabledAll: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    citiesList () {
      return uniqBy(cities.map(item => ({
        value: item.city,
        label: item.city,
      })), i => i.value)
    },
    avaliableAreas () {
      const areas = cities.filter(item => item.city === this.syncCity)
      return areas.map(i => ({
        value: i.area,
        label: i.area,
      }))
    },
    syncCity: {
      get () { return this.city },
      set (val) { this.$emit('update:city', val) },
    },
    syncArea: {
      get () { return this.area },
      set (val) { this.$emit('update:area', val) },
    },
    syncDetail: {
      get () { return this.detail },
      set (val) { this.$emit('update:detail', val) },
    },
  },

  mounted () {
    if (!this.data) return
    const addressParser = new AddressModel(cities)
    const address = addressParser.parse(this.data)
    this.syncCity = address.city
    this.syncArea = address.area
    this.syncDetail = address.address
  },

}
</script>

<style lang="postcss" scoped>
::v-deep.el-select .el-input {
  @apply w-[120px];
}

::v-deep .detail .el-form-item__label{
  @apply p-0;
}
</style>
